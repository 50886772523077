import React from "react";
import { connect } from "react-redux";

import { NotAuthorized } from "../shared/components";

export const AuthorizeChangeRequestMode = (WrappedComponent) => {
    const WithAuthorization = (props) => {
        const { ecoSettings } = props;
        const changeRequestMode = ecoSettings?.find((setting) => setting.key === "changeRequestMode")?.value || false;
        if (changeRequestMode) {
            return <WrappedComponent {...props} />;
        } else {
            return <NotAuthorized />;
        }
    };

    const mapStateToProps = (state) => {
        return {
            ecoSettings: state.ecoSettings
        };
    };

    return connect(mapStateToProps, null)(WithAuthorization);
};
