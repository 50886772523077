const initialState = {
    modifiedPage: null,
    actionType: null,
    validate: false
};

const saveBar = (state = initialState, action) => {
    switch (action.type) {
        case "SET_MODIFIED_PAGE":
            return {
                ...state,
                modifiedPage: action.modifiedPage,
                actionType: null
            }
        case "SAVE_MODIFIED_PAGE":
            return {
                ...state,
                modifiedPage: action.modifiedPage,
                actionType: "save"
            };
        case "VALIDATE_MODIFIED_PAGE":
            return {
                ...state,
                modifiedPage: action.modifiedPage,
                validate: action.validate
            }
        case "REQUEST_APPROVAL":
            return {
                ...state,
                modifiedPage: action.modifiedPage,
                userNote: action.userNote,
                actionType: "save"
            }
        case "DISCARD_MODIFIED_PAGE":
            return {
                ...state,
                modifiedPage: action.modifiedPage,
                actionType: "discard"
            };

        case "CLEAR_MODIFIED_PAGE":
            return initialState;

        default:
            return state;
    }
};

export default saveBar;