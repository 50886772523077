import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hydrateChangeRequests } from "./changeRequestQueueActions";

// material-ui
import { useMediaQuery } from "@mui/material";
import { getDir } from "orion-components/i18n/Config/selector";

const ChangeRequestQueue = (props) => {
    const dispatch = useDispatch();

    const WavCamOpen = useSelector((state) => state.appState.dock.dockData.WavCam);
    const dir = useSelector((state) => getDir(state));

    const minWidth1024Query = useMediaQuery("(min-width:1024px)");
    const minWidth720Query = useMediaQuery("(min-width:720px)");
    const minWidth600Query = useMediaQuery("(min-width:600px)");
    const mixedQuery = useMediaQuery("(max-width:1023px) and (min-width:720px), (min-width:1048px)");

    const mediaQuery = { minWidth1024Query, minWidth720Query, minWidth600Query, mixedQuery };

    const styles = {
        changeRequestContent: {
            height: `calc(100vh - ${WavCamOpen ? "288px" : "48px"})`,
            overflowY: "scroll",
            width: "100vw",
            paddingTop: 52,
            paddingLeft: minWidth1024Query ? 48 : minWidth720Query ? 24 : minWidth600Query ? 16 : 8,
            paddingRight: minWidth1024Query ? 48 : minWidth720Query ? 24 : minWidth600Query ? 16 : 8,
            ...(dir == "rtl"
                ? { marginRight: minWidth1024Query ? 330 : 0 }
                : { marginLeft: minWidth1024Query ? 330 : 0 })
        },
        pending: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontSize: 12,
            margin: "25px 0",
            height: 20
        },
        count: {
            width: 20,
            height: 20,
            borderRadius: "50%",
            background: "#fbb03c",
            color: "#fff",
            fontSize: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #fff",
            margin: "0 10px"
        },
        section: {
            margin: "0 12px",
            color: "#fff"
        },
        headerDesc: {
            fontSize: 12,
            color: "#fff",
            opacity: 0.7,
            display: "flex"
        },
        approve: {
            background: "#4db5f4",
            borderRadius: "5px",
            color: "#fff",
            padding: "10px 20px",
            fontSize: 12,
            "&:hover": {
                background: "#4db5f4"
            }
        },
        deny: {
            borderRadius: "5px",
            color: "#d3615c",
            padding: "10px 20px",
            fontSize: 12,
            "&:hover": {
                background: "transparent"
            }
        },
        tableHeader: {
            color: "#FFFFFF",
            opacity: 0.7,
            fontSize: 12,
            width: "33.33%",
            textTransform: "uppercase",
            border: "none",
            padding: 0,
            ...(dir === "rtl" && { textAlign: "right" })
        },
        sectionHeader: {
            color: "#fff",
            width: "33.33%",
            border: "none",
            fontSize: 12,
            padding: 0,
            height: 25,
            ...(dir === "rtl" && { textAlign: "right" })
        },
        comparisonTable: {
            marginBottom: 20
        },
        requestGroup: {
            width: "100%",
            marginBottom: 40
        },
        avatar: {
            height: 60,
            width: 60,
            background: "#131314"
        },
        divider: {
            position: "absolute",
            left: 0,
            right: 0,
            opacity: 0.3,
            marginTop: "5px"
        },
        iconStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            borderRadius: 2,
            height: 30,
            width: 30,
            borderRadius: 7
        },
        userNote: {
            width: "90%",
            overflowWrap: "break-word"
        }
    }

    useEffect(() => {
        dispatch(hydrateChangeRequests());
    }, []);

    return (<div >{React.cloneElement(props.children, { styles, mediaQuery })}</div>);
};

export default ChangeRequestQueue;