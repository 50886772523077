import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Translate } from "orion-components/i18n";
import CloseIcon from '@mui/icons-material/Close';

const stopPropagation = (e) => {
    e.stopPropagation();
};

const UnsavedChangesModal = ({ open, handleClose, handleDiscard }) => {

    const styles = {
        title: { color: "#fff", letterSpacing: "unset", lineHeight: "unset" },
        continue: { color: "#fff", padding: "10px 20px" },
        discard: { color: "#d3615c", padding: "10px 20px" },
        paperPropStyles: {
            backgroundColor: "#212327",
            padding: "15px",
            alignItems: "center",
            width: "520px"
        }
    };

    return (
        <Dialog
            PaperProps={{
                sx: styles.paperPropStyles
            }}
            open={open}
            disableEnforceFocus={true}
            scroll="paper"
            onMouseDown={stopPropagation}
            onTouchStart={stopPropagation}
        >
            <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%" }}>
                <CloseIcon size={20} style={{ color: "#fff", cursor: "pointer" }} onClick={() => handleClose()} />
            </div>
            <DialogTitle sx={styles.title} disableTypography={true}>
                <h3>
                    <Translate value={"modals.unsavedChanges.title"} />
                </h3>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body2" color="#fff">
                    <p style={{ color: "#fff", opacity: 0.7 }}>
                        <Translate value={"modals.unsavedChanges.desc"} />
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    onMouseDown={stopPropagation}
                    onTouchStart={stopPropagation}
                    sx={styles.continue}
                >
                    <Translate value={"modals.unsavedChanges.continue"} />
                </Button>

                <Button
                    onClick={() => handleDiscard()}
                    onMouseDown={stopPropagation}
                    onTouchStart={stopPropagation}
                    sx={styles.discard}
                >
                    <Translate value={"modals.unsavedChanges.leave"} />
                </Button>
            </DialogActions>
        </Dialog >
    )
};

export default UnsavedChangesModal;