import React, { useCallback, useRef } from "react";
import Dropzone from "react-dropzone";
import { Translate } from "orion-components/i18n";
import PropTypes from "prop-types";

const propTypes = {
	customStyles: PropTypes.object,
	fontStyles: PropTypes.object
};

const defaultProps = {
	customStyles: {},
	labelStyles: {}
};

const ProfilePicDropzone = ({ stageFile, dir, label, customStyles, labelStyles }) => {
	const dropzoneRef = useRef(null);

	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles[0]) {
			// Create a preview URL for each file
			acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file)
				})
			);

			stageFile(acceptedFiles);
		}
	}, []);

	const onOpenClick = () => {
		dropzoneRef.current.open();
	};

	const styles = {
		dropzoneContainer: {
			marginBottom: "10px",
			...(dir && dir == "rtl" ? { marginRight: "6px" } : { marginLeft: "6px" }),
			...(customStyles)
		}
	};

	return (
		<div className="dropzone-container" style={styles.dropzoneContainer}>
			<Dropzone ref={dropzoneRef} onDrop={onDrop} style={{ display: "none" }} accept="image/*">
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()}>
						<input {...getInputProps()} />
					</div>
				)}
			</Dropzone>
			<a onClick={onOpenClick} className="cb-font-b2 cb-font-link" style={labelStyles}>
				{label ? label : <Translate value="mainContent.shared.profileDropzone.uploadBtn" />}
			</a>
		</div>
	);
};

ProfilePicDropzone.propTypes = propTypes;
ProfilePicDropzone.defaultProps = defaultProps;

export default ProfilePicDropzone;
