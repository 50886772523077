import React from "react";
import { List, ListItem, ListItemIcon } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router";
import { useStyles } from "../../../styles/overrides";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "orion-components/AppState/Actions";

const MenuItem = ({
	to,
	selected,
	listItemIcon,
	name,
	dir,
	selectedSubSection,
	handleSection,
	subSection,
	selectedOrder,
	menuItems
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();
	const pathname = location.pathname;
	const lastSegment = pathname.substring(pathname.lastIndexOf("/") + 1).replaceAll("-", "_");
	let menuItem;
	let subSectionItems;
	let subMenu;
	let isSubMenu;
	if (selectedOrder == 2) {
		menuItem = menuItems.find((item) => item.order === 2);
		subSectionItems = menuItem ? menuItem.subSection || [] : [];
		subMenu = subSectionItems.flatMap((subItem) => subItem.subMenu || []);
		isSubMenu = subMenu.some((item) => item.id === lastSegment);
	}
	const modifiedPage = useSelector((state) => state.appState?.saveBar?.modifiedPage);

	const styles = {
		listItem: {
			display: "flex",
			width: 330,
			height: 50,
			position: "relative",
			color: "white",
			...(dir === "ltr" && { paddingLeft: 50 }),
			...(dir === "rtl" && { paddingRight: 50 })
		},
		iconButton: {
			color: "white",
			minWidth: 40
		},
		subSectionItem: {
			...(dir === "ltr" && { paddingLeft: 90 }),
			...(dir === "rtl" && { paddingRight: 90 })
		},
		subMenu: {
			background: "#2b323a",
			padding: 0,
			...(dir === "ltr" && { marginLeft: 15 }),
			...(dir === "rtl" && { marginRight: 15 })
		}
	};

	const handleNavigation = (e, to) => {
		if (modifiedPage && location.pathname !== to) {
			e.preventDefault();
			dispatch(openDialog("unsavedChanges"));
		}
	};

	return (
		<>
			<ListItem
				button
				component={RouterLink}
				to={to}
				style={{
					...styles.listItem,
					...(selected ? { backgroundColor: "#4CB5F4", fontWeight: "bold" } : {})
				}}
				selected={selected}
				classes={{
					root: classes.listItemHover
				}}
				onClick={(e) => handleNavigation(e, to)}
			>
				<ListItemIcon style={styles.iconButton}>{listItemIcon}</ListItemIcon>
				<p className="b1-white" style={{ fontSize: 12, ...(selected ? { fontWeight: "bold" } : {}) }}>
					{name}
				</p>
			</ListItem>
			{selected && subSection && (
				<List style={{ background: "#2b323a", padding: "20px 0" }}>
					{subSection.map((section) => (
						<>
							<ListItem
								button
								component={RouterLink}
								to={section.to}
								style={styles.subSectionItem}
								onClick={(e) => {
									handleNavigation(e, section.to);
									handleSection(section.id);
								}}
								classes={{
									root: classes.subSectionItem
								}}
							>
								<p
									className="b1-white"
									style={{
										fontSize: 12,
										...(selectedSubSection === section.id
											? { color: "#4CB5F4", fontWeight: "bold" }
											: {})
									}}
								>
									{section.name}
								</p>
							</ListItem>
							{section?.subMenu && (
								<List style={styles.subMenu}>
									{section.subMenu.map((menuItem) => (
										<ListItem
											button
											component={RouterLink}
											to={menuItem.to}
											style={styles.subSectionItem}
											classes={{
												root: classes.subSectionItem
											}}
											onClick={(e) => {
												handleNavigation(e, menuItem.to);
												handleSection(menuItem.id);
											}}
										>
											<p
												className="b1-white"
												style={{
													fontSize: 12,
													...((
														isSubMenu
															? lastSegment === menuItem.id
															: selectedSubSection === menuItem.id
													)
														? { color: "#4CB5F4", fontWeight: "bold" }
														: {})
												}}
											>
												{menuItem.name}
											</p>
										</ListItem>
									))}
								</List>
							)}
						</>
					))}
				</List>
			)}
		</>
	);
};

export default MenuItem;
