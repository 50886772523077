import React, { useEffect } from "react";
// material-ui
import { Container } from "@mui/material";

// components
import { Translate } from "orion-components/i18n";
import { getDir } from "orion-components/i18n/Config/selector";
import ChangeRequest from "./ChangeRequest/ChangeRequest";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import { groupOrder } from "../../utility";
import { useNavigate } from "react-router-dom";
import { routes as r } from "../../routes";

const Review = ({ styles, mediaQuery }) => {
    const { minWidth1024Query, minWidth720Query, minWidth600Query, mixedQuery } = mediaQuery;

    const navigate = useNavigate();

    const isAdmin = useSelector((state) => state.session.user.profile.admin);
    const review = useSelector((state) => state.globalData.changeRequests.review);
    const dir = useSelector((state) => getDir(state));

    const groupedRequests = groupBy(review, "level");

    useEffect(() => {
        if (!isAdmin) {
            navigate(r.CHANGE_REQUEST_REQUESTED);
        }
    }, [isAdmin]);

    return (
        <div style={{ display: "flex" }}>
            {/* Main User Account Content */}
            <div style={styles.changeRequestContent}>
                <div
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "80px",
                        maxWidth: minWidth1024Query ? 1120 : 840,
                        width: "100%"
                    }}
                >
                    <div
                        style={{
                            maxWidth: 840,
                            margin: "0 auto"
                        }}
                    >
                        <header style={{ textAlign: "center" }}>
                            <h2>
                                <Translate value={"sideBar.option4"} />
                            </h2>
                            <div className="b1-dark-gray" style={{ marginTop: 15 }}>
                                <Translate value="mainContent.changeRequestQueue.review.titleText" />
                            </div>
                        </header>
                        <section style={styles.pending}>
                            {review && review.length > 0 &&
                                <>
                                    <div style={styles.count}>{review.length}</div>
                                    <Translate value={"mainContent.changeRequestQueue.pendingChanges"} />
                                </>
                            }
                        </section>
                        <Container
                            component={"section"}
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: mixedQuery ? 0 : minWidth600Query ? 8 : 16,
                                paddingBottom: 20,
                                ...(mixedQuery
                                    ? {
                                        boxAlign: "stretch",
                                        alignItems: "stretch",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginLeft: -12,
                                        width: "calc(100% + 24px)"
                                    }
                                    : {})
                            }}
                        >
                            {groupedRequests && groupOrder.map((key) => {
                                const data = groupedRequests[key];

                                return (data &&
                                    <div style={styles.requestGroup}>
                                        <div style={styles.section}>
                                            <Translate value={`mainContent.changeRequestQueue.groups.${key}`} />
                                        </div>
                                        {data
                                            .sort((a, b) => new Date(b.requestedDate) - new Date(a.requestedDate))
                                            .map((item) => {
                                                return <ChangeRequest styles={styles} dir={dir} item={item} canManage={true} />
                                            })
                                        }
                                    </div>
                                )
                            })}
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Review;