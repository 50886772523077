import { changeRequestService } from "client-app-core";
import * as t from "../actionTypes";

export const hydrateChangeRequestsSuccess = (review, requested) => {
    return {
        review,
        requested,
        type: t.HYDRATE_CHANGE_REQUESTS_SUCCESS
    };
};


export const hydrateChangeRequests = () => {
    return (dispatch) => {
        changeRequestService.getByAppId("settings-app", (err, res) => {
            if (err) {
                console.log(err);
            } else {
                dispatch(hydrateChangeRequestsSuccess(res.review, res.requested))
            }
        })
    };
};

export const approveChangeRequest = (requestId) => {
    return (dispatch) => {
        changeRequestService.approve(requestId, (err, res) => {
            if (err) {
                console.log(err);
            } else {
                dispatch(hydrateChangeRequests());
            }
        })
    }
}

export const denyChangeRequest = (requestId) => {
    return (dispatch) => {
        changeRequestService.deny(requestId, (err, res) => {
            if (err) {
                console.log(err);
            } else {
                dispatch(hydrateChangeRequests());
            }
        })
    }
}

export const cancelChangeRequest = (requestId) => {
    return (dispatch) => {
        changeRequestService.cancel(requestId, (err, res) => {
            if (err) {
                console.log(err);
            } else {
                dispatch(hydrateChangeRequests());
            }
        })
    }
}