const initialState = {
    review: null,
    requested: null
};

const changeRequests = (state = initialState, action) => {
    switch (action.type) {
        case "HYDRATE_CHANGE_REQUESTS_SUCCESS":
            return {
                review: action.review,
                requested: action.requested
            }

        default:
            return state;
    }
};

export default changeRequests;