import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//router
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

//material-ui
import {
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

//components
import { getNavigationProps } from "../../../utility";
import { ConfirmationModal, DiscardModal, UnsavedChangesModal, RequestApprovalModal } from "../Modals";
import * as actionCreators from "./saveBarActions";
import { Translate } from "orion-components/i18n";
import { getDir } from "orion-components/i18n/Config/selector";
import { openDialog, closeDialog } from "orion-components/AppState/Actions";
import debounce from "debounce";
import { getEcoSettingByKey } from "orion-components/Services/EcoSettingService/selectors";

const SaveBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { saveModifiedPage, discardModifiedPage, validateModifiedPage, requestApproval, refreshEcosystem, refreshOrganizations } = actionCreators;

  const dialog = useSelector((state) => state.appState.dialog.openDialog);
  const dir = useSelector((state) => getDir(state));
  const modifiedPage = useSelector(
    (state) => state.appState?.saveBar?.modifiedPage
  );

  const [userNote, setUserNote] = useState("");
  const [applied, setApplied] = useState(false);

  const navigationProps = getNavigationProps(location.pathname);
  const parentPath = navigationProps?.parentPath;
  const modal = navigationProps?.modal;
  const doneEditing = navigationProps?.doneEditing;
  const path = navigationProps?.path;
  const validation = navigationProps?.validation;

  const changeRequestMode = useSelector((state) => getEcoSettingByKey("changeRequestMode")(state));

  const styles = {
    label: {
      fontSize: 13,
      margin: "0 10px",
      textTransform: "uppercase",
      display: "flex",
    },
    saveBarContainer: {
      width: "100vw",
      position: "fixed",
      bottom: "20px",
      zIndex: 999,
      display: "flex",
      ...(dir === "rtl" && { padding: "0 378px 0px 48px" }),
      ...(dir === "ltr" && { padding: "0 48px 0px 378px" }),
    },
    discard: {
      fontSize: 12,
      color: "#d3615c",
      margin: "0 20px",
    },
    save: {
      background: "#4db5f4",
      color: "#fff",
      fontSize: 12,
      height: "100%",
      padding: "6px 30px",
      ...(dir === "rtl" && { borderRadius: "5px 0 0 5px" }),
      ...(dir === "ltr" && { borderRadius: "0 5px 5px 0" }),
    },
    doneEditing: {
      fontSize: 12,
      color: "#4db5f4",
      margin: "0 20px",
    },
    iconButton: {
      color: "white",
      height: "100%",
    },
    list: {
      background: "#35373b",
      height: 70,
      borderRadius: "5px",
      display: "flex",
      ...(dir === "rtl" && { padding: "0 30px 0 0" }),
      ...(dir === "ltr" && { padding: "0 0 0 30px" }),
    },
    listItem: {
      width: "auto",
      padding: 0,
    },
  };

  const handleSave = () => {
    dispatch(saveModifiedPage(modal));
    dispatch(closeDialog("confirm"));
  };

  const handleRequest = () => {
    dispatch(requestApproval(modal, userNote));
    setApplied(true);
    setUserNote("");
  };

  const handleDiscardChanges = () => {
    dispatch(discardModifiedPage(modal));
    dispatch(closeDialog("discard"));
    dispatch(closeDialog("unsavedChanges"));
  };

  const handleDoneEditing = () => {
    navigate(parentPath);
  };

  const handleNavigation = (e) => {
    if (modifiedPage) {
      e.preventDefault();
      dispatch(openDialog("unsavedChanges"));
    }
  }

  return (
    <>
      {modifiedPage &&
        <div style={styles.saveBarContainer}>
          <div
            style={{
              margin: "auto",
              width: "calc(100% + 24px)",
              maxWidth: 930,
            }}
          >
            <List component="nav" style={styles.list}>
              {parentPath && (
                <ListItem
                  style={styles.listItem}
                  component={RouterLink}
                  to={parentPath}
                  button
                  onClick={handleNavigation}
                >
                  <IconButton style={styles.iconButton}>
                    {dir && dir === "rtl" ? (
                      <ArrowForward style={{ height: 26, width: 26 }} />
                    ) : (
                      <ArrowBack style={{ height: 26, width: 26 }} />
                    )}
                  </IconButton>
                </ListItem>
              )}
              <ListItem style={{ padding: 0 }}>
                <ListItemText style={styles.label} primary={path} />
                <a
                  onClick={() => dispatch(openDialog("discard"))}
                  className="cb-font-b2 cb-font-link"
                  style={styles.discard}
                >
                  <Translate value={"saveBar.discard"} />
                </a>
                {doneEditing ? (
                  <Button
                    style={styles.doneEditing}
                    onClick={() => validation ? dispatch(validateModifiedPage(modal, true)) : handleDoneEditing()}
                  >
                    <Translate value={"saveBar.doneEditing"} />
                  </Button>
                ) : changeRequestMode ? (
                  <Button
                    style={styles.save}
                    onClick={() => validation ? dispatch(validateModifiedPage(modal, true)) : dispatch(openDialog("requestApproval"))}
                  >
                    <Translate value={"saveBar.requestApproval"} />
                  </Button>
                ) : (
                  <Button
                    style={styles.save}
                    onClick={() => validation ? dispatch(validateModifiedPage(modal, true)) : dispatch(openDialog("confirm"))}
                  >
                    <Translate value={"saveBar.save"} />
                  </Button>
                )}
              </ListItem>
            </List>
          </div>
        </div >}
      <ConfirmationModal
        open={dialog === "confirm"}
        handleSave={handleSave}
        openDiscardDialog={() => {
          dispatch(closeDialog("confirm"));
          dispatch(openDialog("discard"));
        }}
        handleClose={() => dispatch(closeDialog("confirm"))}
      />
      <RequestApprovalModal
        open={applied || dialog === "requestApproval"}
        handleSave={handleRequest}
        openDiscardDialog={() => {
          dispatch(closeDialog("requestApproval"));
          dispatch(openDialog("discard"));
        }}
        handleClose={() => {
          dispatch(refreshEcosystem());
          dispatch(refreshOrganizations());
          debounce(setApplied(false), 1000);
        }}
        userNote={userNote}
        setUserNote={setUserNote}
        applied={applied}
      />
      <DiscardModal
        open={dialog === "discard"}
        handleDiscard={handleDiscardChanges}
        handleClose={() => dispatch(closeDialog("discard"))}
      />
      <UnsavedChangesModal
        open={dialog === "unsavedChanges"}
        handleDiscard={handleDiscardChanges}
        handleClose={() => dispatch(closeDialog("unsavedChanges"))}
      />
    </>
  );
};

export default SaveBar;
