import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Translate, getTranslation } from "orion-components/i18n";
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDir } from "orion-components/i18n/Config/selector";
import { closeDialog } from "orion-components/AppState/Actions";

const stopPropagation = (e) => {
    e.stopPropagation();
};

const RequestApprovalModal = ({ open, handleSave, handleClose, openDiscardDialog, userNote, setUserNote, applied }) => {
    const dispatch = useDispatch();

    const dir = useSelector((state) => getDir(state));

    const styles = {
        title: { color: "#fff", letterSpacing: "unset", lineHeight: "unset" },
        confirm: { background: "#4db5f4", borderRadius: "5px", color: "#fff", padding: "10px 20px" },
        abort: { borderRadius: "5px", color: "#d3615c", padding: "10px 20px" },
        paperPropStyles: {
            backgroundColor: "#212327",
            padding: "15px",
            alignItems: "center",
            width: "520px"
        },
        inputLabelProps: {
            left: "unset",
            fontSize: 10,
            ...(dir && dir === "rtl" ? { transformOrigin: "top right" } : { transformOrigin: "top left" })
        },
        helperText: {
            ...(dir === "rtl" && {
                textAlign: "right",
                opacity: "1!important",
                position: "unset!important"
            })
        },
        done: {
            borderRadius: "5px",
            color: "#4db5f4",
            padding: "10px 20px"
        }
    };

    const getDialogBody = () => {
        const data = {
            ...(applied ? {
                title: "onReview",
                desc: "onReviewDesc",
            } : {
                title: "title",
                desc: "desc"
            })
        };

        return (<>
            <DialogTitle sx={styles.title} disableTypography={true}>
                <h3>
                    <Translate value={`modals.requestApprovalModal.${data.title}`} />
                </h3>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body2" color="#fff">
                    <p style={{ color: "#fff", opacity: 0.7 }}>
                        <Translate value={`modals.requestApprovalModal.${data.desc}`} />
                    </p>
                </DialogContentText>
                {!applied && <TextField
                    variant="outlined"
                    placeholder={getTranslation("modals.requestApprovalModal.placeholder")}
                    value={userNote}
                    style={{
                        width: "100%",
                        marginTop: 15
                    }}
                    InputProps={{
                        style: {
                            padding: 10,
                            height: 80,
                            color: "#fff",
                            background: "#292b30",
                            display: "block"
                        },
                        inputProps: {
                            style: {
                                padding: 0,
                                fontSize: 13
                            }
                        }
                    }
                    }
                    onChange={(e) => setUserNote(e.target.value)}
                    InputLabelProps={{
                        style: styles.inputLabelProps
                    }}
                    FormHelperTextProps={{
                        style: styles.helperText
                    }}
                />}
            </DialogContent>
            <DialogActions>
                {applied ?
                    <Button
                        onClick={() => handleClose()}
                        onMouseDown={stopPropagation}
                        onTouchStart={stopPropagation}
                        sx={styles.done}
                    >
                        <Translate value={"modals.requestApprovalModal.done"} />
                    </Button>
                    : <>
                        <Button
                            onClick={() => openDiscardDialog()}
                            onMouseDown={stopPropagation}
                            onTouchStart={stopPropagation}
                            sx={styles.abort}
                        >
                            <Translate value={"modals.requestApprovalModal.discard"} />
                        </Button>

                        <Button
                            onClick={() => handleSave()}
                            onMouseDown={stopPropagation}
                            onTouchStart={stopPropagation}
                            sx={styles.confirm}
                        >
                            <Translate value={"modals.requestApprovalModal.confirm"} />
                        </Button>
                    </>}
            </DialogActions>
        </>)
    };

    return (
        <Dialog
            PaperProps={{
                sx: styles.paperPropStyles
            }}
            open={open}
            disableEnforceFocus={true}
            scroll="paper"
            onMouseDown={stopPropagation}
            onTouchStart={stopPropagation}
        >
            <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%" }}>
                <CloseIcon size={20} style={{ color: "#fff", cursor: "pointer" }} onClick={() => dispatch(closeDialog("requestApproval"))} />
            </div>
            {getDialogBody()}
        </Dialog >
    )
};

export default RequestApprovalModal;