import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Translate } from "orion-components/i18n";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { closeDialog } from "orion-components/AppState/Actions";

const stopPropagation = (e) => {
    e.stopPropagation();
};

const ReviewModal = ({ desc, dialogAction, open, handleReviewAction, dialog }) => {
    const dispatch = useDispatch();

    const styles = {
        title: { color: "#fff", letterSpacing: "unset", lineHeight: "unset" },
        paperPropStyles: {
            backgroundColor: "#212327",
            padding: "15px",
            alignItems: "center",
            width: "520px"
        },
        approve: {
            background: "#4db5f4",
            borderRadius: "5px",
            color: "#fff",
            padding: "10px 20px",
            fontSize: 12,
            "&:hover": {
                background: "#4db5f4"
            }
        },
        deny: {
            borderRadius: "5px",
            color: "#d3615c",
            padding: "10px 20px",
            fontSize: 12,
            "&:hover": {
                background: "transparent"
            }
        },
        cancel: {
            borderRadius: "5px",
            color: "#d3615c",
            padding: "10px 20px",
            fontSize: 12,
            "&:hover": {
                background: "transparent"
            }
        }
    };

    return (
        <Dialog
            PaperProps={{
                sx: styles.paperPropStyles
            }}
            open={open}
            disableEnforceFocus={true}
            scroll="paper"
            onMouseDown={stopPropagation}
            onTouchStart={stopPropagation}
        >
            <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%" }}>
                <CloseIcon size={20} style={{ color: "#fff", cursor: "pointer" }} onClick={() => dispatch(closeDialog(dialog))} />
            </div>
            <DialogTitle sx={styles.title} disableTypography={true}>
                <h3>
                    <Translate value={"modals.reviewModal.title"} />
                </h3>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="body2" color="#fff">
                    <p style={{ color: "#fff", opacity: 0.7 }}>
                        <Translate value={`modals.reviewModal.${desc}`} />
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleReviewAction()
                        dispatch(closeDialog(dialog))
                    }}
                    onMouseDown={stopPropagation}
                    onTouchStart={stopPropagation}
                    sx={styles[dialogAction]}
                >
                    <Translate value={`modals.reviewModal.${dialogAction}`} />
                </Button>
            </DialogActions>
        </Dialog >
    )
};

export default ReviewModal;