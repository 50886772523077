import React, { useState, useEffect, memo } from "react";

// material-ui
import { List } from "@mui/material";
import { AccountMultiple, CardAccountDetails, CloudCheck } from "mdi-material-ui";
import ListIcon from "@mui/icons-material/List";

//i18n Translator
import { useSelector } from "react-redux";

import { getDir } from "orion-components/i18n/Config/selector";
import MenuItem from "./components/MenuItem";
import { useLocation } from "react-router";
import { getNavigationProps, getSideBarNavItems } from "../../../utility";
import { getEcoSettingByKey } from "orion-components/Services/EcoSettingService/selectors";

const SideNavigation = () => {
	const isEcoAdmin = useSelector((state) => state.session.user.profile.ecoAdmin);
	const isAdmin = useSelector((state) => state.session.user.profile.admin);
	const { review, requested } = useSelector((state) => state.globalData.changeRequests);
	const dir = useSelector((state) => getDir(state));
	const changeRequestMode = useSelector((state) => getEcoSettingByKey("changeRequestMode")(state));

	const [selected, setSelected] = useState(0);
	const [selectedSubSection, setSelectedSubSection] = useState("");

	const location = useLocation();
	const pathname = location.pathname;

	const menuItems = getSideBarNavItems(isAdmin);

	useEffect(() => {
		const order = getNavigationProps(pathname).order;
		setSelected(order);
	}, [pathname]);

	const styles = {
		listItem: {
			display: "flex",
			width: 330,
			height: 50,
			position: "relative",
			color: "white",
			...(dir === "ltr" && { paddingLeft: 50 }),
			...(dir === "rtl" && { paddingRight: 50 })
		},
		iconButton: {
			color: "white",
			minWidth: 40
		},
		subMenuItem: {
			...(dir === "ltr" && { paddingLeft: 90 }),
			...(dir === "rtl" && { paddingRight: 90 })
		},
		changeRequest: {
			display: "flex",
			alignItems: "center",
			position: "relative"
		},
		activeDot: {
			width: 5,
			height: 5,
			borderRadius: "50%",
			background: "#fbb03c",
			position: "absolute",
			left: "-15px"
		}
	};

	const handleSection = (menu) => {
		setSelectedSubSection(menu);
		const scrollIntoViewOptions = {
			inline: "center",
			block: "start",
			behavior: "smooth"
		};
		document.getElementById(menu)?.scrollIntoView(scrollIntoViewOptions);
	};

	const getIcon = (order) => {
		switch (order) {
			case 1:
				return <CardAccountDetails style={{ height: 20, width: 20 }} />;
			case 2:
				return <AccountMultiple style={{ height: 20, width: 20 }} />;
			case 3:
				return <CloudCheck style={{ height: 20, width: 20 }} />;
			case 4:
				return (
					<span style={styles.changeRequest}>
						{(Boolean(review?.length) || Boolean(requested?.length)) && (
							<div style={styles.activeDot}></div>
						)}
						<ListIcon style={{ height: 20, width: 20 }} />
					</span>
				);
		}
	};

	return (
		<div
			className="settings-navigation-panel"
			style={{
				position: "fixed",
				top: 48,
				zIndex: 999 // Reduced the z-index value from 99999 to 999 of the side panel to prevent it from appearing on top of the fullscreen video player
			}}
		>
			<List
				component="nav"
				style={{
					position: "relative",
					padding: "0px",
					background: "#292b30",
					height: "100vh"
				}}
			>
				{menuItems
					.filter((item) => {
						if (item.order === 2) {
							return isAdmin;
						}
						if (item.order === 3) {
							return isEcoAdmin;
						}
						if (item.order === 4) {
							return changeRequestMode;
						}
						return item;
					})
					.map((item) => (
						<MenuItem
							name={item.name}
							to={item.to}
							selected={selected === item.order}
							listItemIcon={getIcon(item.order)}
							dir={dir}
							selectedSubSection={selectedSubSection}
							selectedOrder={selected}
							menuItems={menuItems}
							handleSection={handleSection}
							subSection={item?.subSection}
						/>
					))}
			</List>
		</div>
	);
};

export default memo(SideNavigation);
