import * as t from "../../../actionTypes";
export { refreshEcosystem, refreshOrganizations } from "../../../appActions";

export const setModifiedPage = (modifiedPage) => {
    return {
        type: t.SET_MODIFIED_PAGE,
        modifiedPage
    };
};

export const saveModifiedPage = (modifiedPage) => {
    return {
        type: t.SAVE_MODIFIED_PAGE,
        modifiedPage
    };
};

export const validateModifiedPage = (modifiedPage, validate) => {
    return {
        type: t.VALIDATE_MODIFIED_PAGE,
        modifiedPage,
        validate
    };
};

export const requestApproval = (modifiedPage, userNote) => {
    return {
        type: t.REQUEST_APPROVAL,
        modifiedPage,
        userNote
    };
};

export const discardModifiedPage = (modifiedPage) => {
    return {
        type: t.DISCARD_MODIFIED_PAGE,
        modifiedPage
    };
};

export const clearModifiedPage = () => {
    return {
        type: t.CLEAR_MODIFIED_PAGE
    };
};