import React, { useState } from "react";

export const HookWrapper = (props) => {
    const [globalState, setGlobalState] = useState({
        orgs: null,
        orgApps: {},
        prevApps: {},
        users: [],
        deletedUsers: [],
        integrations: [],
        prevIntegrations: []
    });

    return (
        <div>
            {React.cloneElement(props.children, { globalState, setGlobalState })}
        </div>
    );
};
