import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//material-ui
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, Avatar, Divider } from "@mui/material";

//components
import UserAvatar from "orion-components/UserAvatar";
import { Translate, getTranslation } from "orion-components/i18n";
import { ArticleContainer } from "../../../shared/components";
import { compareChanges } from "../../../utility";
import { approveChangeRequest, denyChangeRequest, cancelChangeRequest } from "../../changeRequestQueueActions";
import { ReviewModal } from "../../../shared/components/Modals";
import { openDialog } from "orion-components/AppState/Dialog/actions";
import groupBy from "lodash/groupBy";
import flatMap from "lodash/flatMap";
import isNil from "lodash/isNil";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import SpriteIcon from "orion-components/SharedComponents/SpriteIcon/SpriteIcon";
import { getEntityIconsByType } from "orion-components/GlobalData/Selectors";

const stopPropagation = (e) => {
    e.stopPropagation();
};

const ChangeRequest = ({ styles, dir, item, canManage }) => {
    const dispatch = useDispatch();

    const entityIcons = useSelector((state) => getEntityIconsByType(state)("frame_icons"));
    const colors = useSelector((state) => getEntityIconsByType(state)("frame_colors"));
    const dialog = useSelector((state) => state.appState.dialog.openDialog);
    const user = useSelector((state) => state.globalData.users[item.requestedBy] || {});
    const locale = useSelector((state) => state.i18n.locale);
    const integrations = useSelector((state) => state.globalData.integrations);
    const orgs = useSelector((state) => state.globalData.orgs);
    const org = orgs[user.orgId];
    const orgRoles = org ? org.roles : [];

    const [values, setValues] = useState([]);

    const unReviewed = item.approved === null;

    const headerDescription = (
        <>
            <div style={styles.headerDesc}>
                <div style={{ width: 95 }}>
                    <Translate value={"mainContent.changeRequestQueue.changeRequest.requestedBy"} />
                </div>
                {`${user.name} (${moment(item.requestedDate).locale(locale).format("MM/DD/YY hh:mma")})`}
            </div>
            <div style={styles.headerDesc}>
                <div style={{ width: 95 }}>
                    <Translate value={"mainContent.changeRequestQueue.changeRequest.userNote"} />
                </div>
                <div style={styles.userNote}>
                    {item.userNote}
                </div>
            </div>
        </>
    );

    useEffect(() => {
        const current = Object.assign({}, item.prevValue);
        const requested = Object.assign({}, item.changes);
        const changes = compareChanges(current, requested, item.level, item.changeType, integrations, orgs, orgRoles, locale);
        setValues(changes);
    }, [item]);

    const getAvatar = (handle, type, target, changeType) => {
        if (!handle && !target) {
            return "-";
        }
        const profileType = changeType === "updateUsers" ? "user" : type;
        switch (profileType) {
            case "user":
                if (handle) {
                    return <Avatar style={styles.avatar} src={"/_download?handle=" + handle} />
                } else {
                    return (
                        <Avatar style={styles.avatar}>
                            <UserAvatar size={60} user={target} />
                        </Avatar>
                    );
                }
            case "organization":
            case "ecosystem":
                if (handle) {
                    const imageURL = "/_download?handle=" + handle;
                    return (
                        <div className="org-icon org-profile-image" style={{ backgroundImage: "url(" + imageURL + ")" }}>
                            <img
                                src={`/_download?handle=" + ${handle}`}
                                alt="profileImage"
                                style={{ visibility: "hidden" }}
                            />
                        </div>
                    )
                } else {
                    let orgName = target.name;
                    orgName = orgName.startsWith("The ") ? orgName.slice(4, orgName.length) : orgName;
                    return (
                        <div className="org-icon">
                            <span>{orgName ? orgName[0] : "O"}</span>
                        </div>
                    )
                }
            default:
                break;
        }

    };

    const getEventTypeIcon = (iconProps) => {
        const icon = iconProps?.split(".");

        if (!icon) {
            return "-";
        }
        return (
            <div
                style={{
                    ...styles.iconStyle,
                    ...{
                        backgroundColor: colors[icon[1]]?.color,
                        height: 30,
                        width: 30,
                        margin: "0px 10px",
                        borderRadius: 7
                    }
                }}
            >
                <SpriteIcon
                    iconName={icon[0]}
                    customHeight="17.5px"
                    customWidth="17.5px"
                />
            </div>
        )
    };

    const isSet = (value) => {
        return !isNil(value) && value !== '';
    }

    const generateChangesTable = () => {
        const filteredValues = values.filter(item => isSet(item));
        const flatArray = flatMap(filteredValues).filter((item) => item.title);
        const groups = groupBy(flatArray, "title");
        const sections = Object.entries(groups);

        return sections.map(([name, data], index) => {
            const uniqueData = uniqWith(data, isEqual);
            return (
                <div style={styles.comparisonTable}>
                    {name !== "undefined" && uniqueData?.length > 0 && <>
                        <Typography style={{ textTransform: "uppercase", fontSize: 12 }}>{name}</Typography>
                        <Table size="small" style={{ position: "relative" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={styles.tableHeader}>
                                        <Translate value="mainContent.changeRequestQueue.changeRequest.table.field" />
                                    </TableCell>
                                    <TableCell style={styles.tableHeader}>
                                        <Translate value="mainContent.changeRequestQueue.changeRequest.table.current" />
                                    </TableCell>
                                    <TableCell style={styles.tableHeader}>
                                        <Translate value="mainContent.changeRequestQueue.changeRequest.table.requestedChange" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {uniqueData.map((value, index) => {
                                    return (!isSet(value.current) && !isSet(value.requested)) ? <></> : (
                                        <>
                                            <TableRow key={`section-${index}`}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{ ...styles.sectionHeader, textTransform: "capitalize" }}
                                                >
                                                    <Translate value={`mainContent.changeRequestQueue.changeRequest.table.${value.field}`} />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={styles.sectionHeader}
                                                >
                                                    {value.field?.includes("handle")
                                                        ? getAvatar(value.current, item.level, value.target, item.changeType)
                                                        : value.field?.includes("iconProps")
                                                            ? getEventTypeIcon(value.current)
                                                            : isSet(value.current)
                                                                ? value.current
                                                                : "-"
                                                    }
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={styles.sectionHeader}
                                                >
                                                    {value.field?.includes("handle")
                                                        ? getAvatar(value.requested, item.level, value.target, item.changeType)
                                                        : value.field?.includes("iconProps")
                                                            ? getEventTypeIcon(value.requested)
                                                            : isSet(value.requested)
                                                                ? value.requested
                                                                : "-"
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                            {index !== sections.length - 1 ? (<Divider style={styles.divider} />) : null}
                        </Table>
                    </>}
                </div>
            )
        })
    };

    return (
        <ArticleContainer
            headerTitle={getTranslation(`mainContent.changeRequestQueue.changeRequest.changeTypes.${item.changeType}`)}
            headerDescription={headerDescription}
            dir={dir}
            headerStyles={{ margin: 0 }}
            titleStyles={{ fontSize: 12, textTransform: "uppercase", marginBottom: 5 }}
        >
            <div style={{ marginTop: 15 }}>
                {generateChangesTable()}
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                {canManage
                    ? unReviewed &&
                    <>
                        <Button
                            onClick={() => dispatch(openDialog(`denyModal_${item.id}`))}
                            onMouseDown={stopPropagation}
                            onTouchStart={stopPropagation}
                            sx={styles.deny}
                        >
                            <Translate value={"mainContent.changeRequestQueue.changeRequest.deny"} />
                        </Button>
                        <Button
                            onClick={() => dispatch(openDialog(`approveModal_${item.id}`))}
                            onMouseDown={stopPropagation}
                            onTouchStart={stopPropagation}
                            sx={styles.approve}
                        >
                            <Translate value={"mainContent.changeRequestQueue.changeRequest.approve"} />
                        </Button>
                    </>
                    :
                    <Button
                        onClick={() => dispatch(openDialog(`cancelRequestModal_${item.id}`))}
                        onMouseDown={stopPropagation}
                        onTouchStart={stopPropagation}
                        sx={styles.deny}
                    >
                        <Translate value={"mainContent.changeRequestQueue.changeRequest.cancel"} />
                    </Button>}
            </div>
            <ReviewModal
                open={dialog === `approveModal_${item.id}`}
                desc={"descAppr"}
                dialogAction={"approve"}
                handleReviewAction={() => dispatch(approveChangeRequest(item.id))}
                dialog={dialog}
            />
            <ReviewModal
                open={dialog === `denyModal_${item.id}`}
                desc={"descDeny"}
                dialogAction={"deny"}
                handleReviewAction={() => dispatch(denyChangeRequest(item.id))}
                dialog={dialog}
            />
            <ReviewModal
                open={dialog === `cancelRequestModal_${item.id}`}
                desc={"descCancel"}
                dialogAction={"cancel"}
                handleReviewAction={() => dispatch(cancelChangeRequest(item.id))}
                dialog={dialog}
            />
        </ArticleContainer >
    )
};

export default ChangeRequest;