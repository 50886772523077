// App
export const IS_FETCHING = "IS_FETCHING";
export const HYDRATE_ECOSYSTEM_SUCCESS = "HYDRATE_ECOSYSTEM_SUCCESS";
export const IS_REFRESHING_ECOSYSTEM = "IS_REFRESHING_ECOSYSTEM";
export const REFRESH_ECOSYSTEM_SUCCESS = "REFRESH_ECOSYSTEM_SUCCESS";
export const REFRESH_ORGANIZATION_SUCCESS = "REFRESH_ORGANIZATION_SUCCESS";
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS";
export const IS_REFRESHING_USER = "IS_REFRESHING_USER";

// UsersDialog
export const UPDATE_USERS_DIALOG_SUCCESS = "UPDATE_USERS_DIALOG_SUCCESS";
export const CLOSE_USERS_DIALOG = "CLOSE_USERS_DIALOG";
export const IS_SUBMITTING_USERS_DIALOG = "IS_SUBMITTING_USERS_DIALOG";

// UserProfile
export const OPEN_CONFIRM_DIALOG = "OPEN_CONFIRM_DIALOG";
export const CLOSE_CONFIRM_DIALOG = "CLOSE_CONFIRM_DIALOG";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const IS_SUBMITTING_USER_UPDATE = "IS_SUBMITTING_USER_UPDATE";
export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS";

// UserPermissions
export const UPDATE_USER_PERMISSIONS = "UPDATE_USER_PERMISSIONS";
export const RESET_SAVE_STATE = "RESET_SAVE_STATE";

// UserIntegrations
export const ADD_INTEGRATION = "ADD_INTEGRATION";
export const REMOVE_INTEGRATION = "REMOVE_INTEGRATION";
export const UPDATE_INTEGRATION = "UPDATE_INTEGRATION";

// UserApps
export const ADD_APPLICATION = "ADD_APPLICATION";
export const REMOVE_APPLICATION = "REMOVE_APPLICATION";

// SharingPolicies
export const IS_SUBMITTING_SHARE_DIALOG = "IS_SUBMITTING_SHARE_DIALOG";
export const CLOSE_SHARE_DIALOG = "CLOSE_SHARE_DIALOG";
export const SHARE_DIALOG_ERROR = "SHARE_DIALOG_ERROR";
export const SHARE_INT_SUCCESS = "SHARE_INT_SUCCESS";

//Alerts
export const EVENT_LOOKUPS_RECEIVED = "EVENT_LOOKUPS_RECEIVED";

// PermissionsDialog
export const OPEN_PERMISSIONS_DIALOG = "OPEN_PERMISSIONS_DIALOG";
export const CLOSE_PERMISSIONS_DIALOG = "CLOSE_PERMISSIONS_DIALOG";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const LOAD_PERMISSIONS_SUCCESS = "LOAD_PERMISSIONS_SUCCESS";
export const PERMISSIONS_DIALOG_WORKING = "PERMISSIONS_DIALOG_WORKING";
export const PERMISSIONS_DIALOG_DONE = "PERMISSIONS_DIALOG_DONE";
export const PERMISSIONS_DIALOG_ERROR = "PERMISSIONS_DIALOG_ERROR";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const HAS_ERROR = "HAS_ERROR";

// OrgProfile
export const DELETE_ORG_SUCCESS = "DELETE_ORG_SUCCESS";
export const FETCH_ORG_SUCCESS = "FETCH_ORG_SUCCESS";
export const UPDATE_ORG_SUCCESS = "UPDATE_ORG_SUCCESS";
export const IS_SUBMITTING_ORG_UPDATE = "IS_SUBMITTING_ORG_UPDATE";

// OrgMembers
export const UPDATE_USER_ACTIVE = "UPDATE_USER_ACTIVE";

// OrgIntegrations
export const OPEN_USERS_DIALOG = "OPEN_USERS_DIALOG";
export const OPEN_SHARE_DIALOG = "OPEN_SHARE_DIALOG";
export const ASSIGN_INTEGRATION = "ASSIGN_INTEGRATION";

// OrgApps
export const ASSIGN_APP = "ASSIGN_APP";
export const USERS_DIALOG_LOAD_SUCCESS = "USERS_DIALOG_LOAD_SUCCESS";
export const IS_LOADING_USERS_DIALOG = "IS_LOADING_USERS_DIALOG";
export const IS_POPULATING_USERS_DIALOG = "IS_POPULATING_USERS_DIALOG";

// NewUser
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

// NewOrg
export const CREATE_ORG_SUCCESS = "CREATE_ORG_SUCCESS";
export const CREATE_ORG_ERROR = "CREATE_ORG_ERROR";
export const CLEAR_ORG_ERROR = "CLEAR_ORG_ERROR";

// Login
// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FETCH_VERSION_SUCCESS = "FETCH_VERSION_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";

// ChangePassword
export const IS_SUBMITTING_PASSWORD = "IS_SUBMITTING_PASSWORD";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_ERROR = "PASSWORD_CHANGE_ERROR";
export const CLEAR_PASSWORD_STATE = "CLEAR_PASSWORD_STATE";

// EcoProfile
export const GET_ALL_ORG_APPS_SUCCESS = "GET_ALL_ORG_APPS_SUCCESS";
export const GET_ALL_ORGS_SUCCESS = "GET_ALL_ORGS_SUCCESS";

// Sharing Tokens
export const SHARING_TOKEN_STATUS_RECEIVED = "SHARING_TOKEN_STATUS_RECEIVED";

// SaveBar
export const SET_MODIFIED_PAGE = "SET_MODIFIED_PAGE";
export const SAVE_MODIFIED_PAGE = "SAVE_MODIFIED_PAGE";
export const VALIDATE_MODIFIED_PAGE = "VALIDATE_MODIFIED_PAGE";
export const REQUEST_APPROVAL = "REQUEST_APPROVAL";
export const DISCARD_MODIFIED_PAGE = "DISCARD_MODIFIED_PAGE";
export const CLEAR_MODIFIED_PAGE = "CLEAR_MODIFIED_PAGE";

//Change Request Queue
export const HYDRATE_CHANGE_REQUESTS_SUCCESS = "HYDRATE_CHANGE_REQUESTS_SUCCESS";
